import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const translations = {
  en: {
    title: "Pricing",
    subtitle: "Get started with our premium features, including up to 1000 orders per month, WhatsApp Marketing messages, and Sales Enable, all for a competitive price.",
    perMonth: "month",
    perBranch: "branch",
    signUpForFree: "Sign up for free",
    startNow: "Start now",
    contactUs: "Contact us",
    recommended: "Recommended",
  },
  he: {
    title: "אז כמה זה עולה לי?",
    subtitle: "התחילו עם התכונות הפרימיום שלנו, כולל עד 1000 הזמנות בחודש, הודעות שיווק בוואטסאפ, ואפשרות מכירות, הכל במחיר תחרותי.",
    perMonth: "לחודש",
    perBranch: "לסניף",
    signUpForFree: "הירשמו בחינם",
    startNow: "התחילו עכשיו",
    contactUs: "צרו קשר",
    recommended: "מומלץ",
  }
};

const tiers = [
  {
    title: { en: 'Free', he: 'חינם' },
    price: '0',
    per: 'month',
    description: {
      en: [
        'Up to 5 orders per month',
        'WhatsApp Marketing messages',
        'Accept payments through WhatsApp',
      ],
      he: [
        'עד 5 הזמנות בחודש',
        'הודעות שיווק בוואטסאפ',
        'קבלת תשלומים דרך וואטסאפ',
      ],
    },
    buttonText: 'signUpForFree',
    buttonVariant: 'outlined',
  },
  {
    title: { en: 'Professional', he: 'מקצועי' },
    subheader: 'recommended',
    price: '30',
    per: 'month',
    description: {
      en: [
        'Up to 1000 orders per month',
        'WhatsApp Marketing messages',
        'Accept payments through WhatsApp',
        'cancel anytime',
      ],
      he: [
        'עד 1000 הזמנות בחודש',
        'הודעות שיווק בוואטסאפ',
        'קבלת תשלומים דרך וואטסאפ',
        'ביטול בכל עת',
      ],
    },
    buttonText: 'startNow',
    buttonVariant: 'contained',
  },
  {
    title: { en: 'Enterprise', he: 'ארגוני' },
    price: '30',
    per: 'branch',
    description: {
      en: [
        'Unlimited orders',
        'WhatsApp Marketing messages',
        'Accept payments through WhatsApp',
        'Smart customer insights',
      ],
      he: [
        'הזמנות ללא הגבלה',
        'הודעות שיווק בוואטסאפ',
        'קבלת תשלומים דרך וואטסאפ',
        'תובנות לקוח חכמות',
      ],
    },
    buttonText: 'contactUs',
    buttonVariant: 'outlined',
  },
];

export default function Pricing({ language = 'en' }) {
  const isRTL = language === 'he';
  
  const handleButtonClick = (tierTitle) => {
    if (tierTitle === 'Enterprise') {
      scrollToSection('contactUs');
    } else {
      window.open('https://chat.uni-chat.app/download', '_blank');
    }
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const offset = 80; // Adjust this value based on your header height
      const elementPosition = sectionElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h3" color="text.primary">
          {translations[language].title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {translations[language].subtitle}
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title[language]}
            xs={12}
            sm={tier.title.en === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title.en === 'Professional' ? '1px solid' : undefined,
                borderColor:
                  tier.title.en === 'Professional' ? 'primary.main' : undefined,
                background:
                  tier.title.en === 'Professional'
                    ? 'linear-gradient(#033363, #021F3B)'
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color:
                      tier.title.en === 'Professional' ? 'primary.contrastText' : '',
                  }}
                >
                  <Typography component="h3" variant="h6" align={isRTL ? 'right' : 'left'}>
                    {tier.title[language]}
                  </Typography>
                  {tier.title.en === 'Professional' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={translations[language].recommended}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                          marginLeft: isRTL ? 0 : 1,
                          marginRight: isRTL ? 1 : 0,
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color:
                      tier.title.en === 'Professional'
                        ? 'primary.contrastText'
                        : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2" align={isRTL ? 'right' : 'left'}>
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6" align={isRTL ? 'right' : 'left'}>
                    &nbsp; {translations[language][`per${tier.per === 'month' ? 'Month' : 'Branch'}`]}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                    alignItems: isRTL ? 'right' : 'left'
                  }}
                />
                {tier.description[language].map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title.en === 'Professional'
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="span"
                      variant="subtitle2"
                      align={isRTL ? 'right' : 'left'}
                      sx={{
                        color:
                          tier.title.en === 'Professional'
                            ? 'primary.contrastText'
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  color='secondary'
                  component="a"
                  onClick={() => handleButtonClick(tier.title.en)}
                  target="_blank"
                >
                  {translations[language][tier.buttonText]}
                </Button>
              </CardActions>
            </Card>
         
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}