import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import axios from 'axios';

// import images
import sushiTimeLogo from './img/sushiTimeLogo.svg';
import bfree from './img/bfree.svg';
import premier from './img/premier.svg';
import foodAndFruits from './img/Food And Fruits.svg';

const translations = {
  en: {
    title: "Featured Stores",
    subtitle: "Finally you want to get started. We recommend these stores for you!",
    notice: "Remember when you add credit or even small orders to your account, we'll give you cash back on your purchases!",
    orderButton: "Try It Now",
    buildYourOwnButton: "Build my demo store",
    noCredit: "*No Credit Required",
    sushiTime: {
      title: "Sushi Time",
      description: "Sushi shop with a variety of fresh sushi and sashimi",
    },
    bFree: {
      title: "bFree",
      description: "Electronics store, mobile phones and accessories with cash back",
    },
    fruitStore: {
      title: "Food and Fruit",
      description: "Fresh fruits that will upgrade your event with fancy fruit platters",
    },
    premier: {
      title: "Premier Dead-Sea",
      description: "Sushi shop with a variety of fresh sushi and sashimi",
    },
  },
  he: {
    title: "נסו אותנו חינם",
    subtitle: "סוף סוף אתם רוצים להשתדרג. רוצים חנות ב- וואטסאפ!",
    notice: "נשמח שקודם תנסו חנויות דומות או אפילו תבנו חנות דמו חינם על מוצרים שלכם!! בחרו למטה איזה סוג עסק הכי מתאים ודומה לעסק שלכם. זכרו אלו חנויות עובדות של לקוחות שלנו - אל תחייבו סתם!",
    orderButton: "נסה את זה עכשיו",
    buildYourOwnButton: "בניית חנות דמו חינם",
    noCredit: "*ללא צורך באשראי",
    sushiTime: {
      title: "סושי טיים",
      description: "חנות סושי עם מגוון סושי ניגירי ומגשי סשימי טריים",
    },
    bFree: {
      title: "בי פרי",
      description: "משווק מורשה של בזק וחנות למוצרי אלקטרוניקה, מכשירי סלולר ואביזרים",
    },
    fruitStore: {
      title: "פוד אנד פרויט",
      description: "העסק שישדרג את האירוע שלכם עם מגשי פירות מעוצבים ועוד",
    },
    premier: {
      title: "פרמייר ים-המלח",
      description: "מותג בינלאומי לקוסמטיקה עם מגוון רחב של מוצרים לטיפול העור, הפנים ועוד",
    },
  },
};

const FeaturedStore = ({ title, description, imageSrc, onOrder, language, imgBackgroundColor = 'black' }) => {
  const isRTL = language === 'he';

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}>
      <CardMedia
        component="img"
        height="140"
        image={imageSrc}
        sx={{ backgroundColor: imgBackgroundColor }}
        alt={title}
      />
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box>
          <Typography gutterBottom variant="h6" component="div" align={isRTL ? 'right' : 'left'}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary" align={isRTL ? 'right' : 'left'}>
            {description}
          </Typography>
        </Box>
        <Button 
          variant="outlined" 
          color="secondary" 
          onClick={onOrder}
          sx={{ 
            mt: 2,
            alignSelf: 'center',
          }}
        >
          {translations[language].orderButton}
        </Button>
      </CardContent>
    </Card>
  );
};

export default function FeaturedStores({ language = 'en' }) {
  const isRTL = language === 'he';

  const handleTryAStore = (storeName) => {
    window.open(storeName, '_blank');
  };
    
  const handleBuildYourDemo = () => {
    window.open('https://chat.uni-chat.app/download', '_blank');
  };

  const featuredStores = [
    {
      title: translations[language].sushiTime.title,
      description: translations[language].sushiTime.description,
      imageSrc: sushiTimeLogo,
      imgBackgroundColor: 'black',
      orderLink: "https://wa.me/972557045000"
    },
    {
      title: translations[language].bFree.title,
      description: translations[language].bFree.description,
      imageSrc: bfree,
      imgBackgroundColor: 'white',
      orderLink: "https://wa.me/972533459020"
    },
    {
      title: translations[language].fruitStore.title,
      description: translations[language].fruitStore.description,
      imageSrc: foodAndFruits,
      imgBackgroundColor: 'black',
      orderLink: "https://wa.me/972552877769"
    },
    {
      title: translations[language].premier.title,
      description: translations[language].premier.description,
      imageSrc: premier,
      imgBackgroundColor: 'black',
      orderLink: "https://wa.me/34631929830"
    }
  ];

  return (
    <Box
      id="GiveUsATry"
      sx={{
        bgcolor: 'background.paper',
        pt: 4,
        pb: 4,
        pr: 4,
        pl: 4,
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
        }}
      >
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {translations[language].title}
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          {translations[language].subtitle}
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary" paragraph>
          {translations[language].notice}
        </Typography>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component='a'
            onClick={handleBuildYourDemo}
            target="_blank"
            sx={{
              borderRadius: '50px',
              padding: '12px 24px',
              direction: isRTL ? 'rtl' : 'ltr',
              '& .MuiButton-endIcon': {
                marginLeft: isRTL ? 0 : '8px',
                marginRight: isRTL ? '8px' : 0,
              },
            }}
            endIcon={language !== 'he' ? <ArrowForward /> : <ArrowBack />}
          >
            {translations[language].buildYourOwnButton}
          </Button>
        </Box>
        <Box sx={{ mt: 0, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body2" align="center" color="text.secondary" paragraph>
            {translations[language].noCredit}
          </Typography>
        </Box>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {featuredStores.map((store, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FeaturedStore
                title={store.title}
                description={store.description}
                imageSrc={store.imageSrc}
                language={language}
                imgBackgroundColor={store.imgBackgroundColor}
                onOrder={() => handleTryAStore(store.orderLink)}
              />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component='a'
            onClick={handleBuildYourDemo}
            sx={{
              borderRadius: '50px',
              padding: '12px 24px',
              direction: isRTL ? 'rtl' : 'ltr',
              '& .MuiButton-endIcon': {
                marginLeft: isRTL ? 0 : '8px',
                marginRight: isRTL ? '8px' : 0,
              },
            }}
            endIcon={language !== 'he' ? <ArrowForward /> : <ArrowBack />}
          >
            {translations[language].buildYourOwnButton}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
