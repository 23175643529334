import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { ArrowForward, ArrowBack } from '@mui/icons-material';

const translations = {
  en: {
    title: "WhatsApp Store",
    description: "Uni WhatsApp Store enables You to sell their products and services directly to customers through WhatsApp, using natural language conversations.",
    features: [
      {
        number: '01',
        title: 'Automated responses 24/7',
        description: 'Our AI agent is available around the clock, providing instant responses and personalized assistance to customers at any time.',
      },
      {
        number: '02',
        title: 'Answer FAQs in any language',
        description: 'Our AI agent can answer frequently asked questions in any language, recommending products and services from the catalog and help you close sales.',
      },
      {
        number: '03',
        title: 'Accept payments through WhatsApp',
        description: 'Our AI agent can accept payments through WhatsApp, providing a seamless shopping experience for customers.',
      },
    ],
    buildYourOwnButton: "Give us a try",
  },
  he: {
    title: "חנות וואטסאפ",
    description: "חנות וואטסאפ Uni מאפשרת לך למכור את המוצרים והשירותים שלך ישירות ללקוחות דרך וואטסאפ, באמצעות שיחות בשפה טבעית.",
    features: [
      {
        number: '01',
        title: 'מענה אוטומטי 24/7',
        description: 'הסוכן החכם שלנו זמין סביב השעון, מספק תגובות מיידיות וסיוע מותאם אישית ללקוחות בכל עת.',
      },
      {
        number: '02',
        title: 'מענה לשאלות נפוצות בכל שפה',
        description: 'הסוכן החכם שלנו יכול לענות על שאלות נפוצות בכל שפה, להמליץ על מוצרים ושירותים מהקטלוג ולעזור לך לסגור מכירות.',
      },
      {
        number: '03',
        title: 'קבלת תשלומים דרך וואטסאפ',
        description: 'הסוכן החכם שלנו יכול לקבל תשלומים דרך וואטסאפ, מספק חוויית קנייה חלקה ללקוחות.',
      },
    ],
    buildYourOwnButton: "נסה אותנו",
  }
};

const ScrollingProductBanner = ({ language = 'en', frontGif }) => {
  const containerRef = useRef(null);
  const t = translations[language] || translations.en; // Fallback to English if language not found
  const isRTL = language === 'he';

  return (
    <div className="relative min-h-[600vh] md:min-h-[400vh]" ref={containerRef}>
      <div className="sticky top-0 h-screen w-full">
        <div className="w-full h-full max-w-6xl mx-auto px-2 md:px-8 grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-12 items-center">
          {/* Left column with GIF */}
          <div className="relative w-full aspect-square flex items-center justify-center">
            <div className="absolute inset-[2%] bg-gradient-to-br from-blue-500 to-purple-500 rounded-3xl opacity-10" />
            <img
              src={frontGif}
              alt="App Demo GIF"
              className="absolute inset-0 w-full h-full object-contain z-10"
            />
          </div>
          {/* Right column with text content */}
          <div className="relative h-full flex items-center justify-center -mt-8 md:mt-0">
            <div className="w-full h-full relative">
              <ScrollingContent t={t} containerRef={containerRef} isRTL={isRTL} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ScrollingContent = ({ t = {}, containerRef, isRTL }) => {
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  });

  // Ensure t has all required properties with defaults
  const sections = [
    {
      content: (
        <div className="space-y-4 md:space-y-6">
          <h2 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent leading-tight">
            {t.title || ''}
          </h2>
          <p className="text-lg md:text-xl text-gray-600 leading-relaxed max-w-xl">
            {t.description || ''}
          </p>
        </div>
      ),
      threshold: [0, 0.15, 0.3],
    },
    ...(t.features || []).map((feature, index) => ({
      content: (
        <div className="max-w-xl" dir={isRTL ? 'rtl' : 'ltr'}>
          <div className="flex gap-4 md:gap-6 items-start">
            <span className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              {feature.number || `0${index + 1}`}
            </span>
            <div className="space-y-2 md:space-y-3">
              <h3 className="text-xl md:text-2xl font-semibold text-gray-800">
                {feature.title || ''}
              </h3>
              <p className="text-base md:text-lg text-gray-600 leading-relaxed">
                {feature.description || ''}
              </p>
            </div>
          </div>
        </div>
      ),
      threshold: [0.15 + index * 0.15, 0.3 + index * 0.15, 0.45 + index * 0.15],
    })),
    {
      content: (
        <button 
          onClick={() => window.open('https://chat.uni-chat.app/download', '_blank')}
          className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-6 md:px-8 py-3 md:py-4 rounded-full text-base md:text-lg font-semibold flex items-center gap-3 hover:opacity-90 transition-opacity shadow-lg"
        >
          {t.buildYourOwnButton || ''}
          {!isRTL ? <ArrowForward /> : <ArrowBack />}
        </button>
      ),
      threshold: [0.85, 0.9, 1],
    },
  ];

  return (
    <div className="relative h-full">
      {sections.map((section, index) => (
        <ScrollSection
          key={index}
          scrollYProgress={scrollYProgress}
          threshold={section.threshold}
          isLastSection={index === sections.length - 1}
        >
          {section.content}
        </ScrollSection>
      ))}
    </div>
  );
};

const ScrollSection = ({
  children,
  scrollYProgress,
  threshold: [start, peak, end],
  isLastSection,
}) => {
  const opacity = useTransform(
    scrollYProgress,
    isLastSection ? [start, peak] : [start, peak, end],
    isLastSection ? [0, 1] : [0, 1, 0]
  );

  const y = useTransform(
    scrollYProgress,
    [start, peak, end],
    [100, 0, -100]
  );

  return (
    <motion.div
      style={{ opacity, y }}
      className="absolute inset-0 flex items-center justify-center"
    >
      <div className="w-full">{children}</div>
    </motion.div>
  );
};

export default ScrollingProductBanner;